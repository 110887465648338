import { FC } from 'react';
import Image from 'next/image';
import { GetServerSideProps } from 'next';
import { getSession } from 'next-auth/react';
import { Row } from 'antd';

import { LoginForm } from '~/components';

const LoginPage: FC = () => (
  <div style={{ height: '100vh' }}>
    <Row
      style={{
        position: 'relative',
        height: '100%',
        flexDirection: 'column',
        background: '#FFF'
      }}
      justify="center"
      align="middle"
    >
      <div style={{ marginBottom: 70 }}>
        <Image
          src="/images/grasias_logo.png"
          width={250}
          height={89}
          alt="Лого Grasias"
        />
      </div>
      <Row>
        <LoginForm />

        {/* <Divider
          type="vertical"
          style={{
            flexGrow: 1,
            height: 'auto',
            margin: '0 50px'
          }}
        /> */}

        {/* <div style={{ width: 350 }}>
          <p>Войти через социальную сеть</p>

          <Row>
            <Button
              shape="circle"
              style={{
                width: 64,
                height: 64,
                padding: 0,
              }}
              type="link"
            >
              <Image
                src="/images/google-icon.png"
                width={62}
                height={62}
                alt="Лого Google"
              />
            </Button>
            <Button
              shape="circle"
              style={{
                width: 64,
                height: 64,
                padding: 0,
                marginLeft: 16
              }}
              type="link"
              onClick={() => signIn('yandex', {
                callbackUrl: `${process.env.DOMAIN}/accounting`
              })}
            >
              <Image
                src="/images/yandex-icon.png"
                width={62}
                height={62}
                alt="Лого Yandex"
              />
            </Button>
          </Row>
        </div> */}
      </Row>
    </Row>
  </div>
);

export default LoginPage;

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const session = await getSession(ctx);

  if (session?.user) {
    return {
      redirect: {
        destination: '/',
        permanent: false,
      },
    };
  }

  return {
    props: {
      session
    },
  };
};
